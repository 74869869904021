<!--
 * @Author: wang peng
 * @Description:  新增/编辑
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="道闸品牌" prop="brand">
      <ykc-dropdown
        :remote="true"
        :data="brandType"
        v-model="ruleForm.brand"
        :disabled="drawerType === 'edit'"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item label="道闸名称" prop="name">
      <ykc-input placeholder="请输入道闸名称" maxlength="30" v-model="ruleForm.name"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="归属电站" prop="stationId">
      <ykc-dropdown
        :remote="true"
        :data="stationIdData"
        v-model="ruleForm.stationId"
        :disabled="drawerType === 'edit'"></ykc-dropdown>
    </ykc-form-item>

    <ykc-form-item label="协议模式" prop="protocol">
      <ykc-radio
        :data="[
          // { id: 1, name: 'server' },
          { id: 2, name: 'client' },
        ]"
        v-model="ruleForm.protocol"></ykc-radio>
    </ykc-form-item>

    <ykc-form-item label="场站编码" prop="gateCode" v-if="ruleForm.protocol === 2">
      <ykc-input
        placeholder="请输入场站编码"
        maxlength="30"
        v-model="ruleForm.gateCode"
        :disabled="drawerType === 'edit'"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="第三方账号" prop="thirdAccount" v-if="ruleForm.protocol === 2">
      <ykc-input placeholder="请输入第三方账号" v-model="ruleForm.thirdAccount"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="应用编码" prop="applicationCode">
      <ykc-input
        placeholder="请输入应用编码"
        maxlength="30"
        v-model="ruleForm.applicationCode"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="秘钥" prop="secret">
      <ykc-input
        placeholder="请输入秘钥"
        type="textarea"
        maxlength="5000"
        v-model="ruleForm.secret"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="停车减免类型" prop="discountType" v-if="ruleForm.protocol === 1">
      <ykc-radio
        :data="[
          { id: 1, name: '固定时长' },
          { id: 2, name: '充电时长' },
        ]"
        v-model="ruleForm.discountType"></ykc-radio>
    </ykc-form-item>

    <ykc-form-item
      label="固定时长时间"
      prop="fixedTime"
      v-if="ruleForm.discountType === 1 && ruleForm.protocol === 1">
      <ykc-input
        type="number"
        placeholder="请输入固定时长时间"
        maxlength="30"
        v-model="ruleForm.fixedTime"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="订单推送地址" prop="orderAddress" v-if="ruleForm.protocol === 2">
      <ykc-input
        placeholder="请输入订单推送地址"
        maxlength="100"
        v-model="ruleForm.orderAddress"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="减免方式" prop="deductionType" v-if="ruleForm.brand === 'LF'">
      <ykc-radio
        :data="[
          { id: 1, name: '全部减免' },
          { id: 2, name: '减免时间' },
          { id: 3, name: '折扣减免' },
          { id: 4, name: '减免金额' },
        ]"
        v-model="ruleForm.deductionType"
        @change="handledeductionType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item label="减免方式" prop="deductionType" v-else-if="ruleForm.brand === 'JS'">
      <ykc-radio
        :data="[
          { id: 1, name: '全部减免' },
          { id: 2, name: '减免时间' },
          { id: 4, name: '减免金额' },
        ]"
        v-model="ruleForm.deductionType"
        @change="handledeductionType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item
      label="减免方式"
      prop="deductionType"
      v-else-if="['KT', 'DC'].includes(ruleForm.brand)">
      <ykc-radio
        :data="[
          { id: 2, name: '减免时间' },
          { id: 4, name: '减免金额' },
        ]"
        v-model="ruleForm.deductionType"
        @change="handledeductionType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item label="减免时间" prop="deductionValue" v-if="ruleForm.deductionType === 2">
      <ykc-input placeholder="请输入减免时间" maxlength="30" v-model="ruleForm.deductionValue">
        <template slot="append">分钟</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="折扣减免" prop="deductionValue" v-if="ruleForm.deductionType === 3">
      <ykc-input placeholder="请输入折扣减免" maxlength="30" v-model="ruleForm.deductionValue">
        <template slot="append">折</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="减免金额" prop="deductionValue" v-if="ruleForm.deductionType === 4">
      <ykc-input placeholder="请输入减免金额" maxlength="30" v-model="ruleForm.deductionValue">
        <template slot="append">元</template>
      </ykc-input>
    </ykc-form-item>

    <ykc-form-item label="备注">
      <ykc-input
        maxlength="30"
        type="textarea"
        placeholder="请输入备注"
        v-model="ruleForm.remark"></ykc-input>
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { validateFloat, validateInt } from '@/utils/validate';
  import { barrierPage } from '@/service/apis';
  import { loadDicItem } from '@/utils/dictionary';

  export default {
    props: {
      id: {
        type: Number,
      },
      drawerType: {
        type: String,
        default: 'add',
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        stationIdData: [], // 归属电站数据
        brandType: [], // 道闸品牌数据
        ruleForm: {
          brand: '',
          name: '',
          stationId: null,
          protocol: 2,
          secret: '',
          applicationCode: '',
          discountType: 1,
          fixedTime: null,
          gateCode: '',
          thirdAccount: '',
          orderAddress: '',
          deductionType: null, // 减免方式
          deductionValue: null, // 减免值
          remark: '',
        },
        rules: {
          brand: [{ required: true, message: '请选择道闸品牌', trigger: 'blur' }],
          name: [
            { required: true, message: '请输入道闸名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('道闸名称'),
            },
          ],
          stationId: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          protocol: [{ required: true, message: '请选择协议模式', trigger: 'blur' }],
          secret: [
            { required: true, message: '请输入秘钥', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.secretTypeLF.regexp,
            //   message: regexpObj.regexp.input.secretTypeLF.errorTips.error('秘钥'),
            // },
          ],
          applicationCode: [
            { required: true, message: '请输入应用编码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('应用编码'),
            },
          ],
          discountType: [{ required: true, message: '请选择停车减免类型', trigger: 'blur' }],
          fixedTime: [
            { required: true, message: '请输入固定时长时间', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('固定时长时间'),
            },
          ],
          gateCode: [
            { required: true, message: '请输入场站编码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('场站编码'),
            },
          ],
          thirdAccount: [
            { required: true, message: '请输入第三方账号', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.accountType.regexp,
            //   message: regexpObj.regexp.input.accountType.errorTips.error('第三方账号'),
            // },
          ],
          orderAddress: [
            { required: true, message: '请输入订单推送地址', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.addressType.regexp,
            //   message: regexpObj.regexp.input.addressType.errorTips.error('订单推送地址'),
            // },
          ],
          deductionType: [{ required: true, message: '请选择请选择减免方式', trigger: 'blur' }],
          deductionValue: [
            { required: true, message: '请输入减免值', trigger: 'blur' },
            {
              trigger: 'blur',
              validator: this.deductionValueCheck,
            },
          ],
        },
      };
    },
    created() {
      this.brandType = loadDicItem('barrier_brand_type');
      this.doFetchOrgInfos();
      // 编辑回显
      if (this.id) {
        console.log('ok+++++');
        barrierPage
          .detail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
          })
          .catch(() => {});
      }
    },
    computed: {
      formData() {
        const formData = {
          brand: this.ruleForm.brand,
          name: this.ruleForm.name,
          stationId: this.ruleForm.stationId,
          protocol: this.ruleForm.protocol,
          secret: this.ruleForm.secret,
          applicationCode: this.ruleForm.applicationCode,
          discountType: this.ruleForm.discountType,
          fixedTime: this.ruleForm.fixedTime,
          gateCode: this.ruleForm.gateCode,
          thirdAccount: this.ruleForm.thirdAccount,
          orderAddress: this.ruleForm.orderAddress,
          deductionType: this.ruleForm.deductionType,
          deductionValue: this.ruleForm.deductionValue,
          remark: this.ruleForm.remark,
        };

        if (this.id) {
          formData.id = this.id;
        }

        return formData;
      },
    },
    methods: {
      /**
       * 减免值校验
       */
      deductionValueCheck(rule, value, callback) {
        console.log('---------', value);
        const { deductionValue } = this.ruleForm;
        if (this.ruleForm.deductionType === 2 && !validateInt(deductionValue, [0, 1444])) {
          callback(new Error('请输入正确的减免时间（0~1444）'));
        } else if (
          this.ruleForm.deductionType === 3 &&
          !validateFloat(deductionValue, [0, 10.0], 1)
        ) {
          callback(new Error('请输入正确的减免折扣（0.0~10.0）'));
        } else if (
          this.ruleForm.deductionType === 4 &&
          !validateFloat(deductionValue, [0.0, 999999.99])
        ) {
          callback(new Error('请输入正确的减免金额（0.00~999999.99）'));
        } else {
          callback();
        }
      },
      /**
       * 切换减免方式
       */
      handledeductionType(e) {
        console.log('----', e);
        this.ruleForm.deductionValue = '';
      },
      /**
       * @desc 获取归属列表
       * */
      doFetchOrgInfos() {
        barrierPage
          .findStationList({})
          .then(res => {
            res.map(item => {
              return this.stationIdData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              barrierPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              // console.log('编辑', this.formData);
              barrierPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /**
       *校验
       * */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
