var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ykc-form',{ref:"ruleForm",staticClass:"el-form-wrap",attrs:{"model":_vm.ruleForm,"rules":_vm.rules}},[_c('ykc-form-item',{attrs:{"label":"道闸品牌","prop":"brand"}},[_c('ykc-dropdown',{attrs:{"remote":true,"data":_vm.brandType,"disabled":_vm.drawerType === 'edit'},model:{value:(_vm.ruleForm.brand),callback:function ($$v) {_vm.$set(_vm.ruleForm, "brand", $$v)},expression:"ruleForm.brand"}})],1),_c('ykc-form-item',{attrs:{"label":"道闸名称","prop":"name"}},[_c('ykc-input',{attrs:{"placeholder":"请输入道闸名称","maxlength":"30"},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('ykc-form-item',{attrs:{"label":"归属电站","prop":"stationId"}},[_c('ykc-dropdown',{attrs:{"remote":true,"data":_vm.stationIdData,"disabled":_vm.drawerType === 'edit'},model:{value:(_vm.ruleForm.stationId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "stationId", $$v)},expression:"ruleForm.stationId"}})],1),_c('ykc-form-item',{attrs:{"label":"协议模式","prop":"protocol"}},[_c('ykc-radio',{attrs:{"data":[
        // { id: 1, name: 'server' },
        { id: 2, name: 'client' },
      ]},model:{value:(_vm.ruleForm.protocol),callback:function ($$v) {_vm.$set(_vm.ruleForm, "protocol", $$v)},expression:"ruleForm.protocol"}})],1),(_vm.ruleForm.protocol === 2)?_c('ykc-form-item',{attrs:{"label":"场站编码","prop":"gateCode"}},[_c('ykc-input',{attrs:{"placeholder":"请输入场站编码","maxlength":"30","disabled":_vm.drawerType === 'edit'},model:{value:(_vm.ruleForm.gateCode),callback:function ($$v) {_vm.$set(_vm.ruleForm, "gateCode", $$v)},expression:"ruleForm.gateCode"}})],1):_vm._e(),(_vm.ruleForm.protocol === 2)?_c('ykc-form-item',{attrs:{"label":"第三方账号","prop":"thirdAccount"}},[_c('ykc-input',{attrs:{"placeholder":"请输入第三方账号"},model:{value:(_vm.ruleForm.thirdAccount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "thirdAccount", $$v)},expression:"ruleForm.thirdAccount"}})],1):_vm._e(),_c('ykc-form-item',{attrs:{"label":"应用编码","prop":"applicationCode"}},[_c('ykc-input',{attrs:{"placeholder":"请输入应用编码","maxlength":"30"},model:{value:(_vm.ruleForm.applicationCode),callback:function ($$v) {_vm.$set(_vm.ruleForm, "applicationCode", $$v)},expression:"ruleForm.applicationCode"}})],1),_c('ykc-form-item',{attrs:{"label":"秘钥","prop":"secret"}},[_c('ykc-input',{attrs:{"placeholder":"请输入秘钥","type":"textarea","maxlength":"5000"},model:{value:(_vm.ruleForm.secret),callback:function ($$v) {_vm.$set(_vm.ruleForm, "secret", $$v)},expression:"ruleForm.secret"}})],1),(_vm.ruleForm.protocol === 1)?_c('ykc-form-item',{attrs:{"label":"停车减免类型","prop":"discountType"}},[_c('ykc-radio',{attrs:{"data":[
        { id: 1, name: '固定时长' },
        { id: 2, name: '充电时长' },
      ]},model:{value:(_vm.ruleForm.discountType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "discountType", $$v)},expression:"ruleForm.discountType"}})],1):_vm._e(),(_vm.ruleForm.discountType === 1 && _vm.ruleForm.protocol === 1)?_c('ykc-form-item',{attrs:{"label":"固定时长时间","prop":"fixedTime"}},[_c('ykc-input',{attrs:{"type":"number","placeholder":"请输入固定时长时间","maxlength":"30"},model:{value:(_vm.ruleForm.fixedTime),callback:function ($$v) {_vm.$set(_vm.ruleForm, "fixedTime", $$v)},expression:"ruleForm.fixedTime"}})],1):_vm._e(),(_vm.ruleForm.protocol === 2)?_c('ykc-form-item',{attrs:{"label":"订单推送地址","prop":"orderAddress"}},[_c('ykc-input',{attrs:{"placeholder":"请输入订单推送地址","maxlength":"100"},model:{value:(_vm.ruleForm.orderAddress),callback:function ($$v) {_vm.$set(_vm.ruleForm, "orderAddress", $$v)},expression:"ruleForm.orderAddress"}})],1):_vm._e(),(_vm.ruleForm.brand === 'LF')?_c('ykc-form-item',{attrs:{"label":"减免方式","prop":"deductionType"}},[_c('ykc-radio',{attrs:{"data":[
        { id: 1, name: '全部减免' },
        { id: 2, name: '减免时间' },
        { id: 3, name: '折扣减免' },
        { id: 4, name: '减免金额' },
      ]},on:{"change":_vm.handledeductionType},model:{value:(_vm.ruleForm.deductionType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "deductionType", $$v)},expression:"ruleForm.deductionType"}})],1):(_vm.ruleForm.brand === 'JS')?_c('ykc-form-item',{attrs:{"label":"减免方式","prop":"deductionType"}},[_c('ykc-radio',{attrs:{"data":[
        { id: 1, name: '全部减免' },
        { id: 2, name: '减免时间' },
        { id: 4, name: '减免金额' },
      ]},on:{"change":_vm.handledeductionType},model:{value:(_vm.ruleForm.deductionType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "deductionType", $$v)},expression:"ruleForm.deductionType"}})],1):(['KT', 'DC'].includes(_vm.ruleForm.brand))?_c('ykc-form-item',{attrs:{"label":"减免方式","prop":"deductionType"}},[_c('ykc-radio',{attrs:{"data":[
        { id: 2, name: '减免时间' },
        { id: 4, name: '减免金额' },
      ]},on:{"change":_vm.handledeductionType},model:{value:(_vm.ruleForm.deductionType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "deductionType", $$v)},expression:"ruleForm.deductionType"}})],1):_vm._e(),(_vm.ruleForm.deductionType === 2)?_c('ykc-form-item',{attrs:{"label":"减免时间","prop":"deductionValue"}},[_c('ykc-input',{attrs:{"placeholder":"请输入减免时间","maxlength":"30"},model:{value:(_vm.ruleForm.deductionValue),callback:function ($$v) {_vm.$set(_vm.ruleForm, "deductionValue", $$v)},expression:"ruleForm.deductionValue"}},[_c('template',{slot:"append"},[_vm._v("分钟")])],2)],1):_vm._e(),(_vm.ruleForm.deductionType === 3)?_c('ykc-form-item',{attrs:{"label":"折扣减免","prop":"deductionValue"}},[_c('ykc-input',{attrs:{"placeholder":"请输入折扣减免","maxlength":"30"},model:{value:(_vm.ruleForm.deductionValue),callback:function ($$v) {_vm.$set(_vm.ruleForm, "deductionValue", $$v)},expression:"ruleForm.deductionValue"}},[_c('template',{slot:"append"},[_vm._v("折")])],2)],1):_vm._e(),(_vm.ruleForm.deductionType === 4)?_c('ykc-form-item',{attrs:{"label":"减免金额","prop":"deductionValue"}},[_c('ykc-input',{attrs:{"placeholder":"请输入减免金额","maxlength":"30"},model:{value:(_vm.ruleForm.deductionValue),callback:function ($$v) {_vm.$set(_vm.ruleForm, "deductionValue", $$v)},expression:"ruleForm.deductionValue"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1):_vm._e(),_c('ykc-form-item',{attrs:{"label":"备注"}},[_c('ykc-input',{attrs:{"maxlength":"30","type":"textarea","placeholder":"请输入备注"},model:{value:(_vm.ruleForm.remark),callback:function ($$v) {_vm.$set(_vm.ruleForm, "remark", $$v)},expression:"ruleForm.remark"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }